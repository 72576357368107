import textResource from '../../../../utils/text-resources/digifinans/NO/messages';
import submit from '../../../../mappers/FI/application';
import LOAN_LIMITS from '../../../../localizations/config/NO/shared/loan-limits';
import validatePhoneNumberNo from '../../../../validators/NO/phoneNumber';
import email from '../../../../validators/FI/email';
import validateTotalLoan from '../../../../validators/totalLoan';
import validateRepaymentTime from '../../../../validators/repaymentTime';

const tooltip = (text) => {
  const temp = {
    trigger: 'focus',
    text,
    width: 'fill',
    position: 'right',
  };
  return temp;
};

const data = {
  general: {
    mapper: submit,
  },
  links: {
    quickReapply: '/returning-customer/',
    quickReapplyEng: '/en/returning-customer/',
  },
  slides: [
    {
      title: '',
      coApplicantSlide: false,
      fields: [
        {
          component: 'ValidatedForm',
          form: 'Slider',
          props: {
            label: textResource.applicationLoanAmountLabel,
            showLabel: true,
            name: 'totalAmount',
            showButtons: false,
            type: 'tel',
            lead: true,
            layout: {
              start: 1,
              end: 4,
            },
            sliderConfig: {
              range: {
                min: 5000,
                max: 800000,
              },
              step: LOAN_LIMITS.STEP,
            },
          },
        },
        {
          component: 'StoreValueDisplay',
          props: {
            value: 'totalAmount',
            fontWeight: 'bold',
            noLabel: true,
            fontSize: '1rem',
            fieldDisplay: {
              name: 'totalAmount',
              maxValue: 800000,
              validate: validateTotalLoan,
              validateOnRegister: true,
              type: 'tel',
              suffix: textResource.loanCalculatorCurrencySuffix,
              isNumber: true,
              placeholder: textResource.applicationLoanAmountPlaceholder,
              unstyled: true,
            },
            layout: {
              start: 5,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Slider',
          props: {
            name: 'repaymentTime',
            type: 'tel',
            lead: true,
            showLabel: true,
            label: textResource.repaymentTimeLabel,
            sliderConfig: {
              range: {
                min: 1,
                max: 15,
              },
            },
            layout: {
              start: 1,
              end: 4,
            },
          },
        },
        {
          component: 'StoreValueDisplay',
          props: {
            value: 'repaymentTime',
            fontWeight: 'bold',
            position: 'space-between',
            fontSize: '1rem',
            noLabel: true,
            fieldDisplay: {
              name: 'repaymentTime',
              type: 'tel',
              maxValue: 15,
              validate: validateRepaymentTime,
              validateOnRegister: true,
              textAlign: '',
              suffix: textResource.loanCalculatorRepaymentTimeSuffix,
              placeholder: textResource.repaymentTimePlaceholder,
              unstyled: true,
            },
            layout: {
              start: 5,
              end: 7,
            },
          },
        },
        {
          component: 'FormTextBlock',
          props: {
            text: textResource.repaymentTimeWarning,
            transparent: false,
            visibleWhen: 'return(formState.values.repaymentTime > 5)',
            margin: true,
            special: true,
            center: false,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'EmailInput',
          sectionName: 'applicant',
          props: {
            name: 'phoneNumber',
            type: 'tel',
            tooltip: tooltip(textResource.phoneNumberTooltip),
            label: textResource.phoneNumberLabel,
            placeholder: textResource.phoneNumberPlaceholder,
            hasValidateFunction: true,
            validate: validatePhoneNumberNo,
            lead: true,
            layout: {
              start: 1,
              end: 4,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'email',
            label: textResource.emailLabel,
            placeholder: textResource.emailPlaceholder,
            tooltip: tooltip(textResource.emailTooltip),
            hasValidateFunction: true,
            validate: email,
            lead: true,
            layout: {
              start: 4,
              end: 7,
            },
          },
        },
        {
          component: 'MonthlyCost',
          props: {
            label: textResource.monthlyCostApproximateMonthlyCostLabel,
            approximateMonthlyCostMobile: textResource.approximateMonthlyCostMobile,
            repaymentTimeLimit: '>5',
            minimal: true,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'AcceptNewsLetter',
          form: 'Checkbox',
          props: {
            name: 'acceptNewsletter',
            text: textResource.samblaPlusText,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'UserAgreementDataPolicy',
          props: {
            lead: true,
            userAgreement: 'https://digifinans.no/brukeravtale',
            dataPolicy: 'https://digifinans.no/personvernerklaering',
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'Teleporter',
          sectionName: 'applicant',
          props: {
            digiRedirect: true,
            sendAbandonCartEvent: true,
            label: textResource.nextSlide,
            ctaBtnStyle: true,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'Disclaimer',
          props: {
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'FormBottom',
          props: {
            label: textResource.safeAndSecure,
            trustPilotData: {
              locale: 'no-NO',
              buData: '5e14704c6cea320001a2f32f',
            },
          },
        },
      ],
    },
  ],
};

export default data;
